import React from 'react';

import './button.sass'

function Button(props) {
    let style = ''
    if (typeof props.style === 'object') {
        props.style.map(modifierClass => {
            return style += ` button--${modifierClass}`
        })
    } else {
        style = ` button--${props.style}`
    }

    return (
        <>
            {props.url ?
                <a href={props.url} target="_blank" rel="noopener noreferrer" onClick={props.onClick} className={props.style ? 'button' + style : 'button'} parameter={props.parameter} id={props.id} >{props.text ? props.text : 'Click me'}</a>
                :
                <button onClick={props.onClick} className={props.style ? 'button' + style : 'button'} parameter={props.parameter} id={props.id} disabled={props.disabled}>{props.text ? props.text : 'Click me'}</button>
            }
        </>
    );
}

export default Button;
