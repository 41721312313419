import React, {useState} from 'react';

import Tooltip from './tooltip'

function TableInfo(props) {
    const [weldingTooltip, setWeldingTooltip] = useState(false)
    const [edgeTooltip, setEdgeTooltip] = useState(false)

    function triggerWeldingTooltip() {
        setWeldingTooltip(!weldingTooltip)
        setEdgeTooltip(false)
    }

    function triggerEdgeTooltip() {
        setEdgeTooltip(!edgeTooltip)
        setWeldingTooltip(false)
    }
    return (
        <div className="table-mobile u-bt">
            <table className="table-mobile__table">
                <tbody>
                    {Object.entries(props.data).map((col, i) => {
                        return <tr key={i}>
                            <td className="u-pos-rel">
                                {col[0]}
                                {col[0] === 'Welding Pos' ? <span className="tooltip-icon" onClick={triggerWeldingTooltip}>
                                    {weldingTooltip && <Tooltip type="welding" />}
                                </span> : ''}
                                {col[0] === 'Edge Prep' ? <span className="tooltip-icon" onClick={triggerEdgeTooltip}>
                                    {edgeTooltip && <Tooltip type="edge" />}
                                </span> : ''}

                            </td>
                            <td>{col[1]}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default TableInfo;
