import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import Button from './button'

import './product.sass'

function Product(props) {
    const { data: { table }} = props
    const [hasOptions, setHasOptions] = useState(false)

    useEffect(() => {
        const options = table ? true : false
        setHasOptions(options)
    }, [table])

    function onProductLink() {
        ReactGA.event({
            category: 'Product',
            action: props.data.name,
            label: props.data.url
        })
    }

    return (
        <div className="product">
            <img className="product__image" src={props.data.image} alt="" width="178" />
            <div className="product__content">
                <p className="product__heading">{props.data.name}</p>
                <p className="product__copy">{props.data.description}</p>
                <p className="product__copy"><strong>ISO Number:</strong>{props.data.ISONumber}</p>
                {!window.matchMedia('(max-width: 767px)').matches && <Button text="View product" url={props.data.url} onClick={onProductLink} />}
            </div>
            <div className="product__info">
                <p className="product__info-title">Key Features</p>
                <div className="product__info-copy">
                <dl>
                    <dt>Gas Composition:</dt>
                    <dd dangerouslySetInnerHTML={{__html: props.data.composition}}></dd>

                    <dt>Qualities:</dt>
                    <dd dangerouslySetInnerHTML={{__html: props.data.qualities}}></dd>
                </dl>
                </div>
            </div>
            {window.matchMedia('(max-width: 767px)').matches &&
                <div className="u-w100">
                    <Button text="View product" url={props.data.url} target="_blank" />
                    {hasOptions && <Button text="View recommended conditions" style={['link', 'has-arrow-right']} onClick={props.onToggleModal} />}
                </div>
            }
        </div>
    );
}

export default Product;
