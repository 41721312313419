import React from 'react';
import './box.sass'

function Box() {
    return (
        <div className="box">
            <div className="box__inner">
                <p className="box__title">
                    Our selector will help you find the gas that you need in a hassle free and efficient way.
                    <br />
                    Simply select the filter steps below and your results will be updated.
                </p>
            </div>
        </div>
    );
}

export default Box;
