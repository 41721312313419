import React from 'react'

import Button from './button'

import './cta.sass'
import ctaImage from '../images/gas.png'

function Cta(props) {
    return (
        <div className="cta">
            <div>
                <h1 className="cta__title">Looking for another product?</h1>
                <p className="cta__copy">BOC supplies industrial gases for a vast range of industries and processes, including the healthcare, welding, and automotive sectors.
                Offering a wide choice of cylinders, we have the right product for your needs.</p>
            </div>
            <div className="cta__group">
                <img className="cta__img" src={ctaImage} alt="" />
                <div className="cta__actions">
                    <Button text="Search for another product" style={['secondary']} onClick={props.onHandleReset} />
                    <Button text="Browse our online store" url="https://www.boconline.co.uk/shop/en/uk/home" target="_blank" />
                </div>
            </div>
        </div>
    );
}

export default Cta;
