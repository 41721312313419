import React, { useState, useLayoutEffect } from 'react';
import ReactGA from 'react-ga';

import Button from './components/button'
import Box from './components/box'
import Panel from './components/panel'
import Tile from './components/tile'
import Cta from './components/cta'
import Tag from './components/tag'
import Table from './components/table'
import TableMobile from './components/table-mobile'
import DevTools from './dev-tools'

import AluminiumTable from './components/table-aluminium'
import SteelTable from './components/table-steel'
import data from './data.json'
import config from './config'
import './base.sass'

ReactGA.initialize('UA-52741240-1')
ReactGA.pageview(window.location.pathname + window.location.search)

function App() {
    // Current Step
    const [step, setStep] = useState(1)
    // What options have already been selected
    const [selectedOptions, setSelectedOptions] = useState([])
    // Should the product page be displayed?
    const [product, setProduct] = useState(false)
    // Should the modal display?
    const [modal, setModal] = useState(false)

    // When clicking 'Proceed'
    function handleProceed(e) {
        const parameterName = e.currentTarget.attributes.parameter.value
        const id = parseFloat(e.currentTarget.id)
        setSelectedOptions({
            ...selectedOptions,
            [parameterName]: id
        })
        if (step === 4) {
            setProduct(true)
        }
        setStep(step + 1)

        const selectedName = Object.values(data).map(group => {
            if (group.parameter === parameterName) {
                return group.options[id].name
            } else {
                return ''
            }
        }).join('')

        ReactGA.event({
            category: 'Navigation',
            action: `From: ${data[step].type} | To: ${data[step + 1] ? data[step + 1].type : 'product'}`,
            label: selectedName
        })

        ReactGA.event({
            category: 'Selection',
            action: selectedName,
            label: `${parameterName}: ${id} ${selectedName}`
        })

        window.scrollTo(0,0)
    }

    function increaseStep() {
        if (step === 4) {
            setProduct(true)
        }
        setStep(step + 1)
    }

    // When clicking 'Back'
    function handleBack(e) {
        const newOptions = selectedOptions
        const parameter = data[step - 2].parameter

        if (newOptions[parameter] !== undefined) {
            delete newOptions[parameter]
            setSelectedOptions(newOptions)
            setStep(step - 1)
            setProduct(false)
        } else {
            delete newOptions[data[step - 3].parameter]
            setSelectedOptions(newOptions)
            setStep(step - 2)
        }
    }

    // When clicking 'Back' from the product page
    function handleProductBack(e) {
        const newOptions = selectedOptions
        const newOptionsSize = Object.keys(newOptions).length
        const lastParameter = Object.keys(newOptions)[Object.keys(newOptions).length-1];

        delete newOptions[lastParameter]
        setSelectedOptions(newOptions)
        setStep(newOptionsSize)
        setProduct(false)
    }

    // When clicking 'Cancel & Start again'
    function handleReset() {
        setStep(1)
        setSelectedOptions([])
        setProduct(false)
        ReactGA.event({
            category: 'Navigation',
            action: 'Reset',
            label: data[step].type
        })
    }

    // When clicking 'Cancel & Start again'
    function toggleModal() {
        setModal(!modal)
        if (!modal === true) {
            ReactGA.event({
                category: 'Modal',
                action: 'Toggled Modal',
                label: true
            })
        }
    }

    // Skip straight to a product
    function skipToProduct(e) {
        setSelectedOptions({
            processId: 1,
            methodId: 1,
            materialId: 1,
            thicknessId: 1
        })
        setStep(4)
        setProduct(true)
    }

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    function ShowWindowDimensions(props) {
        const [width, height] = useWindowSize();
        return <span>Window size: {width} x {height}</span>;
    }

    // Refresh on orientation change
    ShowWindowDimensions()

    return (
        <>
            {config.isDev && <DevTools onSkip={skipToProduct} onReset={handleReset} selectedOptions={selectedOptions} />}
            {step === 1 && <Box />}
            {step === 1 &&
                <div className="section">
                    <div className="container container--narrow">
                        <div className="u-text-center">
                            <h2>Let’s get started!</h2>
                            <h1>{data[step -1].title}</h1>
                        </div>
                        <div className="u-flex u-flex-wrap u-justify-center">
                            {Object.values(data[step -1].options).map((option, i) => {
                                return <Tile key={i} id={option.id} title={option.name} type={option.type} onHandleProceed={handleProceed} parameter={Object.values(data)[step - 1].parameter} />
                            })}
                        </div>
                    </div>
                </div>
            }
            {step > 1 && !product &&
                <>
                    <div className="container container--narrow">
                        <div className="u-flex u-flex-wrap u-justify-center u-align-center u-mb u-mt">
                            <span className="tag-title">You've chosen:</span>

                            {Object.entries(selectedOptions).map((x, i) => {
                                return <Tag key={i} text={data[i].options[x[1]].name} />
                            })}
                        </div>
                    </div>
                    <div className="container container--narrow">
                        <Panel title={data[step -1].title} onHandleProceed={handleProceed} onHandleBack={handleBack} onHandleReset={handleReset} onIncreaseStep={increaseStep} hasActions="true" prev={data[step -2].type} options={data[step -1].options} parameter={data[step -1].parameter} selectedOptions={selectedOptions} />
                    </div>
                </>
            }
            {product &&
                <div className="container">
                <div className="u-mt-double u-mb-double">
                    <Button style={['link', 'has-arrow-left', 'results']} text="Back to previous steps" onClick={handleProductBack} />
                    <h1 className="heading--giga u-text-center">Your Results</h1>
                    <hr className="hr" />

                    <div className="u-flex u-flex-wrap u-justify-center u-align-center u-mt-double u-mb-double">
                        <span className="tag-title">You have chosen:</span>
                        {Object.keys(data).map((key, i) => {
                            if (selectedOptions[data[key].parameter]) {
                                return <Tag key={i} text={data[key].options[selectedOptions[data[key].parameter]].name} />
                            }
                            return null
                        })}
                    </div>

                    <Panel style={['wide', 'product']} product="true" title={Object.values(data)[4].title} onToggleModal={toggleModal} onHandleProceed={handleProceed} onHandleBack={handleBack} onHandleReset={handleReset} data={Object.values(data)[4].options} parameter={Object.values(data)[4].parameter} selectedOptions={selectedOptions} />

                    {modal &&
                        <div className="modal">
                            <button className="modal__close" onClick={toggleModal}></button>
                            <div className="container">
                                <div className="u-text-center u-mb">
                                    {Object.values(Object.values(data)[4].options).map((product, i) => {
                                        if (( JSON.stringify(product.parameters) === JSON.stringify(selectedOptions) )) {
                                            return <h2 className="u-brand u-mt0 u-mb0" key={i}>{product.name}</h2>
                                        }
                                        return null
                                    })}
                                    <p className="u-size-16 u-color-corduroy u-mt0 u-mb0">Recommended conditions</p>
                                </div>
                                {Object.values(Object.values(data)[4].options).map((product, i) => {
                                    if (( JSON.stringify(product.parameters) === JSON.stringify(selectedOptions) )) {
                                        return <TableMobile key={i} data={product} selectedOptions={selectedOptions} thickness={selectedOptions['thicknessId'] ? data[3].options[selectedOptions['thicknessId']].name : null} />
                                    }
                                    return null
                                })}
                            </div>
                        </div>
                    }

                    <div className="u-flex u-justify-space-between u-flex-wrap u-hide@medium-min">
                    {Object.values(Object.values(data)[4].options).map((product, i) => {
                        if (( JSON.stringify(product.parameters) === JSON.stringify(selectedOptions) )) {
                            return <TableMobile key={i} data={product} selectedOptions={selectedOptions} thickness={selectedOptions['thicknessId'] ? data[3].options[selectedOptions['thicknessId']].name : null} />
                        }
                        return null
                    })}
                    </div>

                    <Table data={Object.values(data)[4]} selectedOptions={selectedOptions} thickness={selectedOptions['thicknessId'] ? data[3].options[selectedOptions['thicknessId']].name : null} />

                    {
                    // Only display if:
                    // Only on MIG/MAG welding product is selected
                    // 2a)  Aluminium - Show aluminium table data
                    // 2b) Stainless steel - Show stainless steel table data
                    }

                    {selectedOptions.methodId === 2 && selectedOptions.materialId === 7 &&
                        <AluminiumTable />
                    }
                    {selectedOptions.materialId === 2 && selectedOptions.materialId === 6 &&
                        <SteelTable />
                    }

                    <Cta onHandleReset={handleReset} />
                </div>
            </div>
            }
        </>
    );
}

export default App;
