import React, {useState} from 'react';

import Tooltip from './tooltip'

import './table.sass'

function generateHeadings(data) {
    let headings = []
    Object.keys(data[0]).map((title, i) => {
        return headings.push(title)
    })
    return headings
}

function Table(props) {
    const [weldingTooltip, setWeldingTooltip] = useState(false)
    const [edgeTooltip, setEdgeTooltip] = useState(false)

    if (!props.data.table) {
        return false
    }

    function triggerWeldingTooltip() {
        setWeldingTooltip(!weldingTooltip)
        setEdgeTooltip(false)
    }

    function triggerEdgeTooltip() {
        setEdgeTooltip(!edgeTooltip)
        setWeldingTooltip(false)
    }

    let tableData = Object.values(props.data.options).map((product, i) => {
        if (( JSON.stringify(product.parameters) === JSON.stringify(props.selectedOptions) )) {
            return product.table
        }
        return null
    })

    if (tableData.includes(undefined)) {
        return false
    }

    return (
        <div className="table">
            <table className="table__table">
                <thead>
                    <tr>
                        {generateHeadings(tableData.filter( Boolean )[0]).map((heading, i) => {
                            return <th key={i} className="u-pos-rel">
                                {heading}
                                {heading === 'Welding Pos' ? <span className="tooltip-icon" onClick={triggerWeldingTooltip}>{weldingTooltip && <Tooltip type="welding" />}</span> : ''}
                                {heading === 'Edge Prep' ? <span className="tooltip-icon" onClick={triggerEdgeTooltip}>{edgeTooltip && <Tooltip type="edge" />}</span> : ''}
                            </th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {tableData.filter( Boolean )[0].map((row, index) => {
                        if (row['Material thickness']) {
                            if (row['Material thickness'] === props.thickness) {
                                return (
                                    <tr key={index}>
                                        {Object.values(row).map((col, i) => {
                                            return <td key={i}>{col}</td>
                                        })}
                                    </tr>
                                )
                            }
                        }
                        return null
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default Table;
