import React from 'react';

import './tooltip.sass'

function Tooltip(props) {
    return (
        <>
            {props.type === 'welding' &&
                <div className="tooltip">
                    <div className="tooltip__close">x</div>
                    <p className="tooltip__title">Welding position</p>
                    <div className="tooltip__content">
                        <ul>
                            <li className="tooltip__table-title">Code</li>
                            <li>1. Overhead</li>
                            <li>2. Vertically up</li>
                            <li>3. Flat</li>
                            <li>4. Flillet</li>
                        </ul>
                        <ul>
                            <li className="tooltip__table-title">Nose</li>
                            <li>(4G, PE)</li>
                            <li>(3G, PF)</li>
                            <li>(IG, PA)</li>
                            <li>(2F, PB)</li>
                        </ul>
                        <ul>
                            <li className="tooltip__table-title">Gap</li>
                            <li>(4G, PE)</li>
                            <li>(3G, PF)</li>
                            <li>(IG, PA)</li>
                            <li>(2F, PB)</li>
                        </ul>
                    </div>
                </div>
            }
            {props.type === 'edge' &&
                <div className="tooltip">
                    <div className="tooltip__close">x</div>
                    <p className="tooltip__title">Edge Preparations</p>
                    <div className="tooltip__content">
                        <ul>
                            <li className="tooltip__table-title">Code</li>
                            <li>1. Square Edge</li>
                            <li>2. Square Edge</li>
                            <li>3. 60 single vee</li>
                            <li>4. 60 single vee</li>
                            <li>5. 60 double vee</li>
                        </ul>
                        <ul>
                            <li className="tooltip__table-title">Nose</li>
                            <li>None</li>
                            <li>None</li>
                            <li>1.0mm</li>
                            <li>2.0mm</li>
                            <li>2.0mm</li>
                        </ul>
                        <ul>
                            <li className="tooltip__table-title">Gap</li>
                            <li>None</li>
                            <li>1.0mm</li>
                            <li>1.0mm</li>
                            <li>2.0mm</li>
                            <li>2.0mm</li>
                        </ul>
                    </div>
                </div>
            }
        </>
    );
}

export default Tooltip;
