import React from 'react';

import './tag.sass'

function Tag(props) {
    return (
        <div className="tag">{props.text ? props.text : 'Tag'}</div>
    );
}

export default Tag;
