import React from 'react';

import './radio.sass'

function Radio(props) {
    const onChange = (e) => {
        props.onChecked(e)
    }

    return (
        <div className={props.checkedInput ? 'radio radio--checked' : 'radio'}>
            <label className="radio__label" htmlFor={props.id}>
                <input className="radio__input" type="radio" name={props.id} id={props.id} value={props.id} checked={props.checkedInput} onChange={onChange} parameter={props.parameter} />
                {props.text ? props.text : 'Radio Label'}
            </label>
        </div>
    );
}

export default Radio;
