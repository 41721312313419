import React from 'react';

import './tile.sass'

import gasIcon from '../images/gas.svg'
import gasIconHover from '../images/gas_hover.svg'
import weldingIcon from '../images/welding.svg'
import weldingIconHover from '../images/welding_hover.svg'

function Tile(props) {
    let icon = ''
    let iconHover = ''
    if (props.type === 'gas' ) {
        icon = gasIcon
        iconHover = gasIconHover
    } else if (props.type === 'welding') {
        icon = weldingIcon
        iconHover = weldingIconHover
    }

    return (
        <div className="tile" id={props.id} parameter={props.parameter} onClick={props.onHandleProceed}>
            {props.title}
            <img className="tile__icon" src={icon} alt="" />
            <img className="tile__icon-hover" src={iconHover} alt="" />
        </div>
    );
}

export default Tile;
