import React from 'react';

import './dev-tools.sass'

function DevTools(props) {
    let optionList = ''
    function options() {
        for (let [key, value] of Object.entries(props.selectedOptions)) {
            optionList += `[${key}: ${value}]`
        }
    }
    options()

    return (
        <>
            <div className="dev-tools">
                <div className="dev-tools__title">DEVTOOLS</div>
                <button onClick={props.onSkip}>Skip to Product</button>
                <button onClick={props.onReset}>Reset</button>
                <div className="dev-tools__options">
                    Selected Options: {optionList}
                </div>
            </div>
        </>
    );
}

export default DevTools;
