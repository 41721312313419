import React from 'react';

function SteelTable(props) {
    return (
        <div className="u-hide@medium-min">
            <p>Guide to selection of filler wires and rods for stainless steel</p>
            <div className="table table--fixed-8">
                <table className="table__table">
                    <thead>
                        <tr>
                            <th>Alloy Type</th>
                            <th>304L</th>
                            <th>316</th>
                            <th>316L</th>
                            <th>321</th>
                            <th>347</th>
                            <th>309</th>
                            <th>310</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>304</td>
                            <td>308</td>
                            <td>308, 316</td>
                            <td>308, 316</td>
                            <td>308, 347</td>
                            <td>308, 347</td>
                            <td>308, 309</td>
                            <td>308, 310</td>
                        </tr>
                        <tr>
                            <td>304L</td>
                            <td>308L</td>
                            <td>308, 316</td>
                            <td>308L, 316L</td>
                            <td>308L, 347</td>
                            <td>308L, 347</td>
                            <td>308, 309</td>
                            <td>308, 310</td>
                        </tr>
                        <tr>
                            <td>316</td>
                            <td></td>
                            <td>316</td>
                            <td>316</td>
                            <td>316, 347</td>
                            <td>316, 347</td>
                            <td>316, 309</td>
                            <td>316, 310</td>
                        </tr>
                        <tr>
                            <td>316L</td>
                            <td></td>
                            <td></td>
                            <td>316L</td>
                            <td>316L</td>
                            <td>316L</td>
                            <td>347, 309</td>
                            <td>316, 310</td>
                        </tr>
                        <tr>
                            <td>321</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>347</td>
                            <td>347</td>
                            <td>347, 309</td>
                            <td>347, 310</td>
                        </tr>
                        <tr>
                            <td>347</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>347</td>
                            <td>309</td>
                            <td>347</td>
                        </tr>
                        <tr>
                            <td>309</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>309</td>
                        </tr>
                        <tr>
                            <td>310</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>310</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p className="u-size-14">NR: Not recommended for welding | NA: Not normally applicable: seek technical advice |  * Some 5000 series alloys are crack sensitive and extra care should be taken</p>
        </div>
    );
}

export default SteelTable;
