import React, { useState } from 'react';

import TableInfo from './table-info'

import './table-mobile.sass'

function TableMobile(props) {
    const [current, setCurrent] = useState(1)

    if (!props.data.table) {
        return false
    }

    function changeCurrent(e) {
        const {direction} = e.currentTarget.dataset

        if (direction === 'minus') {
            if (current - 1 <= 0) {
                return false
            }
            setCurrent(current - 1)
        }

        if (direction === 'plus') {
            setCurrent(current + 1)
        }
    }

    return (
        <>

            {props.data.table.map((data, i) => {
                if (data['Material thickness']) {
                    if (data['Material thickness'] === props.thickness) {
                        return <TableInfo data={data} key={i} />
                        // return generateTable(data, i)
                    }
                }
                return null
            })}

            {
            // Only display if:
            // Only on MIG/MAG welding product is selected
            // 2a)  Aluminium - Show aluminium table data
            // 2b) Stainless steel - Show stainless steel table data
            }
            {props.selectedOptions.methodId === 2 && props.selectedOptions.materialId === 7 &&
                <>
                    <div className="table-mobile u-mb">
                        <p className="u-size-14">Guide to selection of filler wires and rods for aluminium</p>
                        <table className="table-mobile__table">
                            <thead>
                                <tr>
                                    <th>Parent Alloy Type</th>
                                    <th className={current === 1 ? `` : `u-hide`}>
                                        <span className="u-arrow-left u-arrow-grey"></span>
                                            1000
                                        <span onClick={changeCurrent} data-direction="plus" className="u-arrow-right"></span>
                                    </th>
                                    <th className={current === 2 ? `` : `u-hide`}>
                                        <span onClick={changeCurrent} data-direction="minus" className="u-arrow-left"></span>
                                            2000
                                        <span onClick={changeCurrent} data-direction="plus" className="u-arrow-right"></span>
                                    </th>
                                    <th className={current === 3 ? `` : `u-hide`}>
                                        <span onClick={changeCurrent} data-direction="minus" className="u-arrow-left"></span>
                                            3000
                                        <span onClick={changeCurrent} data-direction="plus" className="u-arrow-right"></span>
                                    </th>
                                    <th className={current === 4 ? `` : `u-hide`}>
                                        <span onClick={changeCurrent} data-direction="minus" className="u-arrow-left"></span>
                                            5000*
                                        <span onClick={changeCurrent} data-direction="plus" className="u-arrow-right"></span>
                                    </th>
                                    <th className={current === 5 ? `` : `u-hide`}>
                                        <span onClick={changeCurrent} data-direction="minus" className="u-arrow-left"></span>
                                            6000
                                        <span onClick={changeCurrent} data-direction="plus" className="u-arrow-right"></span>
                                    </th>
                                    <th className={current === 6 ? `` : `u-hide`}>
                                        <span onClick={changeCurrent} data-direction="minus" className="u-arrow-left"></span>
                                            7000
                                        <span className="u-arrow-right u-arrow-grey"></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>7000</td>
                                    <td className={current === 1 ? `` : `u-hide`}>NA</td>
                                    <td className={current === 2 ? `` : `u-hide`}>NR</td>
                                    <td className={current === 3 ? `` : `u-hide`}>NA</td>
                                    <td className={current === 4 ? `` : `u-hide`}>5556, 5356</td>
                                    <td className={current === 5 ? `` : `u-hide`}>5556, 5356</td>
                                    <td className={current === 6 ? `` : `u-hide`}>5556</td>
                                </tr>
                                <tr>
                                    <td>6000</td>
                                    <td className={current === 1 ? `` : `u-hide`}>4034</td>
                                    <td className={current === 2 ? `` : `u-hide`}>NR</td>
                                    <td className={current === 3 ? `` : `u-hide`}>4043</td>
                                    <td className={current === 4 ? `` : `u-hide`}>5356, 5183, 5556</td>
                                    <td className={current === 5 ? `` : `u-hide`}>4043A, 5356, 5183</td>
                                    <td className={current === 6 ? `` : `u-hide`}></td>
                                </tr>
                                <tr>
                                    <td>5000*</td>
                                    <td className={current === 1 ? `` : `u-hide`}>5356, 5183, 4043</td>
                                    <td className={current === 2 ? `` : `u-hide`}>NR</td>
                                    <td className={current === 3 ? `` : `u-hide`}>5356, 5183</td>
                                    <td className={current === 4 ? `` : `u-hide`}>5356, 5183, 5556A</td>
                                    <td className={current === 5 ? `` : `u-hide`}></td>
                                    <td className={current === 6 ? `` : `u-hide`}></td>
                                </tr>
                                <tr>
                                    <td>3000</td>
                                    <td className={current === 1 ? `` : `u-hide`}>100, 4043</td>
                                    <td className={current === 2 ? `` : `u-hide`}>NR</td>
                                    <td className={current === 3 ? `` : `u-hide`}>3103</td>
                                    <td className={current === 4 ? `` : `u-hide`}></td>
                                    <td className={current === 5 ? `` : `u-hide`}></td>
                                    <td className={current === 6 ? `` : `u-hide`}></td>
                                </tr>
                                <tr>
                                    <td>2000</td>
                                    <td className={current === 1 ? `` : `u-hide`}>NR</td>
                                    <td className={current === 2 ? `` : `u-hide`}>NR</td>
                                    <td className={current === 3 ? `` : `u-hide`}></td>
                                    <td className={current === 4 ? `` : `u-hide`}></td>
                                    <td className={current === 5 ? `` : `u-hide`}></td>
                                    <td className={current === 6 ? `` : `u-hide`}></td>
                                </tr>
                                <tr>
                                    <td>1000</td>
                                    <td className={current === 1 ? `` : `u-hide`}>1050A, 4043A</td>
                                    <td className={current === 2 ? `` : `u-hide`}></td>
                                    <td className={current === 3 ? `` : `u-hide`}></td>
                                    <td className={current === 4 ? `` : `u-hide`}></td>
                                    <td className={current === 5 ? `` : `u-hide`}></td>
                                    <td className={current === 6 ? `` : `u-hide`}></td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="u-size-12 u-lh-16">
                        <strong>NR:</strong> Not recommended for welding<br />
                        <strong>NA:</strong> Not normally applicable: seek technical advice<br />
                        <strong>*</strong> Some 5000 series alloys are crack sensitive and extra care should be taken
                        </p>
                    </div>
                </>
            }
            {props.selectedOptions.materialId === 2 && props.selectedOptions.materialId === 6 &&
                <>
                    <div className="table-mobile u-mb">
                        <p className="u-size-14">Guide to selection of filler wires and rods for stainless steel</p>
                        <table className="table-mobile__table">
                            <thead>
                                <tr>
                                    <th>Alloy Type</th>
                                    <th className={current === 1 ? `` : `u-hide`}>
                                        <span className="u-arrow-left u-arrow-grey"></span>
                                            304L
                                        <span onClick={changeCurrent} data-direction="plus" className="u-arrow-right"></span>
                                    </th>
                                    <th className={current === 2 ? `` : `u-hide`}>
                                        <span onClick={changeCurrent} data-direction="minus" className="u-arrow-left"></span>
                                            316
                                        <span onClick={changeCurrent} data-direction="plus" className="u-arrow-right"></span>
                                    </th>
                                    <th className={current === 3 ? `` : `u-hide`}>
                                        <span onClick={changeCurrent} data-direction="minus" className="u-arrow-left"></span>
                                            316L
                                        <span onClick={changeCurrent} data-direction="plus" className="u-arrow-right"></span>
                                    </th>
                                    <th className={current === 4 ? `` : `u-hide`}>
                                        <span onClick={changeCurrent} data-direction="minus" className="u-arrow-left"></span>
                                            321
                                        <span onClick={changeCurrent} data-direction="plus" className="u-arrow-right"></span>
                                    </th>
                                    <th className={current === 5 ? `` : `u-hide`}>
                                        <span onClick={changeCurrent} data-direction="minus" className="u-arrow-left"></span>
                                            347
                                        <span onClick={changeCurrent} data-direction="plus" className="u-arrow-right"></span>
                                    </th>
                                    <th className={current === 6 ? `` : `u-hide`}>
                                        <span onClick={changeCurrent} data-direction="minus" className="u-arrow-left"></span>
                                            309
                                        <span onClick={changeCurrent} data-direction="plus" className="u-arrow-right"></span>
                                    </th>
                                    <th className={current === 7 ? `` : `u-hide`}>
                                        <span onClick={changeCurrent} data-direction="minus" className="u-arrow-left"></span>
                                            310
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>304L</td>
                                    <td className={current === 1 ? `` : `u-hide`}>308</td>
                                    <td className={current === 2 ? `` : `u-hide`}>308L</td>
                                    <td className={current === 3 ? `` : `u-hide`}></td>
                                    <td className={current === 4 ? `` : `u-hide`}></td>
                                    <td className={current === 5 ? `` : `u-hide`}></td>
                                    <td className={current === 6 ? `` : `u-hide`}></td>
                                    <td className={current === 7 ? `` : `u-hide`}></td>
                                </tr>
                                <tr>
                                    <td>316</td>
                                    <td className={current === 1 ? `` : `u-hide`}>308, 316</td>
                                    <td className={current === 2 ? `` : `u-hide`}>308, 316</td>
                                    <td className={current === 3 ? `` : `u-hide`}>316</td>
                                    <td className={current === 4 ? `` : `u-hide`}></td>
                                    <td className={current === 5 ? `` : `u-hide`}></td>
                                    <td className={current === 6 ? `` : `u-hide`}></td>
                                    <td className={current === 7 ? `` : `u-hide`}></td>
                                </tr>
                                <tr>
                                    <td>316L</td>
                                    <td className={current === 1 ? `` : `u-hide`}>308, 316</td>
                                    <td className={current === 2 ? `` : `u-hide`}>308L, 316L</td>
                                    <td className={current === 3 ? `` : `u-hide`}>316</td>
                                    <td className={current === 4 ? `` : `u-hide`}>316L</td>
                                    <td className={current === 5 ? `` : `u-hide`}></td>
                                    <td className={current === 6 ? `` : `u-hide`}></td>
                                    <td className={current === 7 ? `` : `u-hide`}></td>
                                </tr>
                                <tr>
                                    <td>321</td>
                                    <td className={current === 1 ? `` : `u-hide`}>308, 347</td>
                                    <td className={current === 2 ? `` : `u-hide`}>308L, 347L</td>
                                    <td className={current === 3 ? `` : `u-hide`}>316, 347</td>
                                    <td className={current === 4 ? `` : `u-hide`}>316L</td>
                                    <td className={current === 5 ? `` : `u-hide`}>347</td>
                                    <td className={current === 6 ? `` : `u-hide`}></td>
                                    <td className={current === 7 ? `` : `u-hide`}></td>
                                </tr>
                                <tr>
                                    <td>347</td>
                                    <td className={current === 1 ? `` : `u-hide`}>308, 347</td>
                                    <td className={current === 2 ? `` : `u-hide`}>308L, 347</td>
                                    <td className={current === 3 ? `` : `u-hide`}>316, 347</td>
                                    <td className={current === 4 ? `` : `u-hide`}>316L</td>
                                    <td className={current === 5 ? `` : `u-hide`}>347</td>
                                    <td className={current === 6 ? `` : `u-hide`}>347</td>
                                    <td className={current === 7 ? `` : `u-hide`}></td>
                                </tr>
                                <tr>
                                    <td>309</td>
                                    <td className={current === 1 ? `` : `u-hide`}>308, 309</td>
                                    <td className={current === 2 ? `` : `u-hide`}>308, 309</td>
                                    <td className={current === 3 ? `` : `u-hide`}>316, 309</td>
                                    <td className={current === 4 ? `` : `u-hide`}>347, 309</td>
                                    <td className={current === 5 ? `` : `u-hide`}>347, 309</td>
                                    <td className={current === 6 ? `` : `u-hide`}>309</td>
                                    <td className={current === 7 ? `` : `u-hide`}></td>
                                </tr>
                                <tr>
                                    <td>310</td>
                                    <td className={current === 1 ? `` : `u-hide`}>308, 310</td>
                                    <td className={current === 2 ? `` : `u-hide`}>308, 310</td>
                                    <td className={current === 3 ? `` : `u-hide`}>316, 310</td>
                                    <td className={current === 4 ? `` : `u-hide`}>316, 310</td>
                                    <td className={current === 5 ? `` : `u-hide`}>347, 310</td>
                                    <td className={current === 6 ? `` : `u-hide`}>347</td>
                                    <td className={current === 7 ? `` : `u-hide`}>309</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </>
    );
}

export default TableMobile;
