import React from 'react';

function AluminiumTable(props) {
    return (
        <div className="u-hide@large-min">
            <p>Guide to selection of filler wires and rods for aluminium</p>
            <div className="table table--fixed-7">
                <table className="table__table">
                    <thead>
                        <tr>
                            <th>Parent Alloy Type</th>
                            <th>7000</th>
                            <th>6000</th>
                            <th>5000*</th>
                            <th>3000</th>
                            <th>2000</th>
                            <th>1000</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1000</td>
                            <td>NA</td>
                            <td>4034</td>
                            <td>5356, 5183, 4043</td>
                            <td>1100, 4043</td>
                            <td>NR</td>
                            <td>1050A, 4043A</td>
                        </tr>
                        <tr>
                            <td>2000</td>
                            <td>NR</td>
                            <td>NR</td>
                            <td>NR</td>
                            <td>NR</td>
                            <td>NR</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>3000</td>
                            <td>NA</td>
                            <td>4043</td>
                            <td>5356, 5183</td>
                            <td>3103</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>5000*</td>
                            <td>5556, 5356</td>
                            <td>5356, 5183, 5556</td>
                            <td>5356, 5183, 5556A</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>6000</td>
                            <td>5556, 5356</td>
                            <td>4043A, 5356, 5183</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>7000</td>
                            <td>5556</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AluminiumTable;
