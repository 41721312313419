import React, {useState} from 'react'

import Button from './button'
import Radio from './radio'
import Product from './product'

import './panel.sass'

function Panel(props) {
    const [checkedId, setCheckedId] = useState(null)
    const [disabled, setDisabled] = useState(true)

    const optionCollection = []
    const optionNames = []
    let style = ''
    if (typeof props.style === 'object') {
        props.style.map(modifierClass => {
            return style += ` panel--${modifierClass}`
        })
    } else {
        style = ` panel--${props.style}`
    }

    function onChecked(e) {
        setCheckedId(parseFloat(e.currentTarget.id))
        setDisabled(false)
    }

    function handleProceed(e) {
        if (disabled) {
            return false
        }
        props.onHandleProceed(e)
        setCheckedId(null)
        setDisabled(true)
    }

    function handleBack(e) {
        props.onHandleBack(e)
        setCheckedId(null)
        setDisabled(true)
    }

    function buttons() {
        if (Object.keys(props.selectedOptions).length === 2 && props.parameter === "thicknessId" && Object.keys(props.selectedOptions).indexOf("processId") > -1 && Object.keys(props.selectedOptions).indexOf("methodId") > -1) {
            return <Button style={['link', 'has-arrow-left']} text={'Back to method options'} onClick={handleBack} parameter={props.parameter} />
        } else {
            return <Button style={['link', 'has-arrow-left']} text={'Back to ' + props.prev + ' options'} onClick={handleBack} parameter={props.parameter} />
        }
    }

    return (
        <div className={'panel' + style}>
            <div className="panel__header">
                {props.title}
            </div>
            <div className="panel__content">
                {props.product && <div>
                    {Object.values(props.data).map((product, i) => {
                        if (( JSON.stringify(product.parameters) === JSON.stringify(props.selectedOptions) )) {
                            return <Product key={i} data={product} onToggleModal={props.onToggleModal} />
                        }
                        return null
                    })}
                </div>}

                {!props.product && <div className="panel__inner">
                    {Object.values(props.options).map((option, i) => {
                        if (( JSON.stringify(option.parameters) === JSON.stringify(props.selectedOptions) )) {
                            optionCollection.push(true)
                            if (optionNames.includes(option.name)) {
                                return false
                            } else {
                                optionNames.push(option.name)
                                return <Radio text={option.name} id={option.id} key={i} onChecked={onChecked} checkedInput={(checkedId === option.id)} parameter={props.parameter} />
                            }
                        }
                        if (i === (Object.keys(props.options).length - 1) && optionCollection.length === 0) {
                            props.onIncreaseStep()
                        }
                        return null
                    })}
                </div>}
                {props.hasActions && <div className="panel__actions">
                    {buttons()}
                    <Button text='Proceed' onClick={handleProceed} parameter={props.parameter} id={checkedId} disabled={disabled} />
                    <Button style={['link', 'warning']} text='Cancel & start again' onClick={props.onHandleReset} />
                </div>}
            </div>
        </div>
    );
}

export default Panel;
